@import "../../Styles/variables";


h3 {
    padding: 0;
    margin: 0;
    color: $secondary-text;
}

div {
    padding: 0;
}