.projectsPage {
  min-height: calc(100vh - 2em);
  width: 100%;
}

.projectPanel {
  margin: 2em;
  max-width: 1000px;
}
@media (min-width: 1200px) {
  .projectPanel {
    margin: 5em auto;
  }
}

h1 {
  text-align: center;
}

a {
  color: #ffffff;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 0.3em 0;
}
li:hover {
  background-color: #484848;
  opacity: 0.5;
}

.card {
  display: flex;
  flex-direction: column;
  display: flex;
  padding: 1.5em;
  background-color: #353535;
  border-radius: 5px;
}

.carousel {
  width: 100%;
}

.slideImg {
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.videos {
  min-height: 40vh;
}
@media (min-width: 600px) {
  .videos {
    min-height: 60vh;
  }
}/*# sourceMappingURL=styles.module.css.map */