@import "../../Styles/variables";
@import "../../Styles/mixins";
@import "../../Styles/animations";

.profilePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    padding: 1.5em;
    min-height: calc(100vh - 2em);
    
}

.name {
    display: block;
    font-size: 1em;
    padding: 0.5em 0;
    margin-right: auto;
}

.panel {
    display: flex;
    gap: 1.5em;

    @include for-tablet-landscape-down {
        flex-direction: column;
        align-items: center;
    }
}

.spriteImg {
    max-height: 600px;
    border-radius: 15px;
    object-fit: cover;

    @include for-tablet-landscape-down {
        max-height: 400px;
    }
}

.card {
    transition: height 1s ease-out;
    @include make-card(1.5em);
    
    @include for-tablet-landscape-down {
        width: calc(100% - 3em);
        justify-content: center;
    }
}

.cardShrink {
    transition: height 1s ease-out;
    align-items: center;
    @include make-card(1.5em);    
    @include for-tablet-landscape-down {
        width: calc(100% - 3em);
        justify-content: center;
        align-self: baseline;
    }
}

.character {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    font-size: 2em;
    padding-bottom: 1em;
    text-align: center;
}

@mixin hp-bar($color) {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0;
    
    span {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        margin-left: 1em;
        background-color: $color;
        border-radius: 3px;
    }

}

.hp {
    @include hp-bar(#ff2f2f);
}
.mp {
    @include hp-bar(#3a3ae1);
}

.level {
    background-color: #919191;
    border-radius: 5px;
    padding: 0 0.3em;
    margin-right: 0.2em;

}

.charClass {
    font-size: 1em;
    padding-top: 0.5em;
}

.charInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;   
}

form {
    display: grid;
    width: 100%;
    max-width: 1200px;
    padding-bottom: 2em;
    grid-template-columns: 50% 50%;
    row-gap: 0.3em;

    @include for-tablet-landscape-down {
        grid-template-columns: 100%;
    }

    .line {
        display: grid;
        //grid-template-columns: 45% 45%;
        grid-template-columns: repeat(auto-fit);
        padding: 0.5em;
        text-align: center;
        align-items: center;

        label {
            padding-bottom: 0.5em;
        }
    }

    input {
        font-size: 0.8em;
        background-color: $tertiary-background;
        border: none;
        //border-color: $tertiary-background;
        border-radius: 5px;
        color: $text-color;
        text-align: center;
        padding: 0.2em 0.5em;
        &:focus {
            outline: $outline-color solid 2px;
            color: $outline-color;
        }

        @include for-desktop-up {
            font-size: 1em;
        }
    }
}

.skills {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.skillBars {
    display: flex;
    flex-direction: column;
}

.skillBarContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.skillBarWrapper {
    display: flex;
    margin: 1em 0;
}

.skillBar {
    display: flex;
    justify-content: center;
    animation: growWidth 2s ease-in-out;
    height: 10px;
    width: 100%;
}

.status {
    width: 100%;
}