@import "./typography";
@import "./mixins";
@import "./variables";

body {
    font-family: $main-font, Serif;
    color: $text-color;
    //background-color: $background;
    background: radial-gradient(ellipse at top, #1B2735 0%, #090A0F 100%);
    margin: 0;
}

.App {
    display: flex;
    padding: 0;
    margin: 0;
    overflow: hidden;

    @include for-phone-only {
        flex-direction: column;
    }

    ::-moz-selection {
        background: #AFAFAF;
    }
    
    ::-webkit-selection {
        background: #AFAFAF;
    }
    
    ::selection {
        background: #AFAFAF;
    }
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 1em;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
}


@function multiple-box-shadow ($n) {
    $value: '#{random(2000)}px #{random(2000)}px #FFF';
    @for $i from 2 through $n {
        $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF'
    }
    
    @return unquote($value);
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

.stars1 {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
    animation: animStar 100s linear infinite;
    
    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: $shadows-small;
    }
}
.stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
    animation: animStar 125s linear infinite;
    
    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: $shadows-medium;
    }
}
.stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
    animation: animStar 150s linear infinite;
    
    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: $shadows-big;
    }
}

@keyframes animStar {
    from {transform: translateY(0px);}
    to {transform: translateY(-2000px);}
} 