.nonMobile {
  background-color: #5c5c5c;
  display: flex;
  width: 50px;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 50px;
  margin-left: -6px;
  position: fixed;
}
@media (max-width: 599px) {
  .nonMobile {
    display: none;
  }
}

.mobile {
  display: none;
  flex-direction: column;
  align-items: flex-end;
}
.mobile button {
  background: none;
  color: inherit;
  border: none;
  padding: 1em;
  cursor: pointer;
}
.mobile button:hover {
  opacity: 0.5;
}
@media (max-width: 599px) {
  .mobile {
    display: flex;
  }
}

.link {
  color: white;
  padding: 0 1em;
}
.link:hover {
  opacity: 0.6;
}

.links {
  padding: 2em 0 0 0;
  display: flex;
  gap: 2em;
  flex-direction: column;
  list-style-type: none;
}

.socials {
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  list-style-type: none;
  padding-bottom: 0.5em;
}

.drawer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 1000;
}
.drawer button {
  color: black;
}

.mobileLinks {
  display: flex;
  flex-grow: 1;
  width: 100%;
  gap: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobileLink {
  color: black;
  text-decoration: none;
  font-size: 1.5em;
  transition: 0.3s;
}
.mobileLink:hover {
  opacity: 0.4;
}/*# sourceMappingURL=styles.module.css.map */