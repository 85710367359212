@import url("https://fonts.googleapis.com/css?family=Orbitron");
@import url("https://fonts.googleapis.com/css?family=Poppins");
.japanese {
  font-size: 1em;
  padding: 0;
  font-weight: bold;
}

.english {
  font-size: 1em;
  padding: 0;
}

.name {
  font-size: clamp(1rem, 8vw, 4rem);
  padding: 0;
  margin: 0;
  text-shadow: 6px 6px 0px rgb(70, 70, 70);
}

.softwareDeveloper {
  word-wrap: none;
  font-size: clamp(1rem, 8vw, 4rem);
  color: #747474;
  padding: 0;
  margin: 0;
}

.char {
  display: inline-flex;
  transition: all 0.2s;
}
.char:hover {
  transform: scale(1.3);
}

.intro {
  display: flex;
  gap: 0.5em;
  width: 50%;
  flex-grow: 2;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  font-family: "Orbitron";
  padding-left: 5%;
}
.intro .brackets {
  color: #ffffff;
  opacity: 0.2;
}
@media (max-width: 1200px) {
  .intro {
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}

.graphic {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 50%;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .graphic {
    display: none;
  }
}

.topLine {
  width: 50%;
  height: 30%;
  border-right: solid 6px white;
}

.bottomLine {
  flex-grow: 1;
  width: 50%;
  border-right: solid 6px white;
}

.circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 15vw;
  width: 15vw;
  border: solid 6px white;
  border-radius: 50%;
}

.homePage {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.enter {
  opacity: 0;
  transition: opacity 1s;
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(70, 70, 70);
  border-radius: 5px;
  padding: 0.3em 0.5em;
}
.enter:hover {
  animation: bounce 1s infinite;
}

.enterShow {
  opacity: 1;
}

.link {
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-decoration: none;
  color: #ffffff;
  padding: 0.5em 0.7em;
}
.link:hover {
  opacity: 0.5;
}

@keyframes bounce {
  0% {
    transform: translate(-50%, 0%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translate(-50%, 30%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    transform: translate(-50%, 0%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}/*# sourceMappingURL=styles.module.css.map */