@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}
@keyframes growWidth {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.profilePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  padding: 1.5em;
  min-height: calc(100vh - 2em);
}

.name {
  display: block;
  font-size: 1em;
  padding: 0.5em 0;
  margin-right: auto;
}

.panel {
  display: flex;
  gap: 1.5em;
}
@media (max-width: 900px) {
  .panel {
    flex-direction: column;
    align-items: center;
  }
}

.spriteImg {
  max-height: 600px;
  border-radius: 15px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 900px) {
  .spriteImg {
    max-height: 400px;
  }
}

.card {
  transition: height 1s ease-out;
  display: flex;
  padding: 1.5em;
  background-color: #353535;
  border-radius: 5px;
}
@media (max-width: 900px) {
  .card {
    width: calc(100% - 3em);
    justify-content: center;
  }
}

.cardShrink {
  transition: height 1s ease-out;
  align-items: center;
  display: flex;
  padding: 1.5em;
  background-color: #353535;
  border-radius: 5px;
}
@media (max-width: 900px) {
  .cardShrink {
    width: calc(100% - 3em);
    justify-content: center;
    align-self: baseline;
  }
}

.character {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font-size: 2em;
  padding-bottom: 1em;
  text-align: center;
}

.hp {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0;
}
.hp span {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-left: 1em;
  background-color: #ff2f2f;
  border-radius: 3px;
}

.mp {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0;
}
.mp span {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-left: 1em;
  background-color: #3a3ae1;
  border-radius: 3px;
}

.level {
  background-color: #919191;
  border-radius: 5px;
  padding: 0 0.3em;
  margin-right: 0.2em;
}

.charClass {
  font-size: 1em;
  padding-top: 0.5em;
}

.charInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

form {
  display: grid;
  width: 100%;
  max-width: 1200px;
  padding-bottom: 2em;
  grid-template-columns: 50% 50%;
  row-gap: 0.3em;
}
@media (max-width: 900px) {
  form {
    grid-template-columns: 100%;
  }
}
form .line {
  display: grid;
  grid-template-columns: repeat(auto-fit);
  padding: 0.5em;
  text-align: center;
  align-items: center;
}
form .line label {
  padding-bottom: 0.5em;
}
form input {
  font-size: 0.8em;
  background-color: #484848;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  padding: 0.2em 0.5em;
}
form input:focus {
  outline: #9b9b9b solid 2px;
  color: #9b9b9b;
}
@media (min-width: 1200px) {
  form input {
    font-size: 1em;
  }
}

.skills {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skillBars {
  display: flex;
  flex-direction: column;
}

.skillBarContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.skillBarWrapper {
  display: flex;
  margin: 1em 0;
}

.skillBar {
  display: flex;
  justify-content: center;
  animation: growWidth 2s ease-in-out;
  height: 10px;
  width: 100%;
}

.status {
  width: 100%;
}/*# sourceMappingURL=styles.module.css.map */