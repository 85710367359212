.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8823529412);
  display: flex;
  align-items: center;
  justify-content: center;
}

.myModal {
  width: clamp(50%, 1200px, 90%);
  margin: 2em;
  padding: 2em;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;
}/*# sourceMappingURL=styles.module.css.map */