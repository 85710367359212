@import '../../Styles/variables';
@import "../../Styles/mixins";

.nonMobile {
    background-color: $nav-color;
    display: flex;
    width: 50px;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 50px;
    margin-left: -6px; //account for stars width
    position: fixed;

    @include for-phone-only {
        display: none;
    }
}

.mobile {
    display: none;
    flex-direction: column;
    align-items: flex-end;

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 1em;
        cursor: pointer; 
        &:hover {
           opacity: 0.5; 
        }       
    }

    @include for-phone-only {
        display: flex;
    }
}

.link {
    color:  white;
    padding: 0 1em;
    &:hover {
        opacity: 0.6;
    }
}

.links {
    padding: 2em 0 0 0;
    display: flex;
    gap: 2em;
    flex-direction: column;
    list-style-type: none;
}
.socials {
    display: flex;
    gap: 0.5em;
    flex-direction: column;
    list-style-type: none;
    padding-bottom: .5em;

}

.drawer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: $text-color;
    z-index: 1000;

    button {
        color: black;
    }
}

.mobileLinks {
    display: flex;
    flex-grow: 1;
    width: 100%;
    gap: 1em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobileLink {
    color: black;
    text-decoration: none;
    font-size: 1.5em;
    transition: 0.3s;

    &:hover {
        opacity: 0.4;        
    }
}