@import "../../Styles/mixins";
@import "../../Styles/variables";

.projectsPage {
    min-height: calc(100vh - 2em);
    width: 100%;
}

.projectPanel {
    margin: 2em;    
    max-width: 1000px;

    @include for-desktop-up{
        margin: 5em auto;
    }
    
}

h1 {
    text-align: center;
}

a {
    color: $text-color;
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    padding: .3em 0;
    
    &:hover {
        background-color: $tertiary-background;
        opacity: 0.5;
    }
}

.card {
    display: flex;
    flex-direction: column;
    @include make-card(1.5em);
}

.carousel {
    width: 100%;
}

.slideImg {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
    object-fit: cover;
}

.videos {
    min-height: 40vh;

    @include for-tablet-portrait-up {
        min-height: 60vh;
    }
}